const isMobile = () => {
  // if (window.innerWidth / window.innerHeight > 1) {
  if (window.innerWidth > 768) {
    return false;
  } else {
    return true;
  }
};

export default isMobile;
