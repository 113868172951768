import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    overflow: hidden;
    background-color: ${(props) => props.theme.appearance.bgColor};
    color: ${(props) => props.theme.appearance.textColor};

    path {
      fill: ${(props) => props.theme.appearance.textColor} !important;
    }
  }
`;

export default GlobalStyle;
