import { useRef } from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { Description } from './Description';
import styles from './styles.module.scss';

export const Vision = () => {
  const { themeMode } = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={`${styles.vision} ${
        themeMode === 'light' ? styles.light : styles.dark
      }`}
      ref={ref}
    >
      <div className={styles.header}>Vision</div>
      <div className={styles.container1}>
        <div className={styles.title}>
          A NEW ARTISTIC
          <br />
          MOVEMENT
        </div>
        {/* <div className={styles.description}>
          An artistic movement refers to a specific artistic trend or flow that
          emerges during a certain period or in a particular place. Artistic
          movements play a significant role in the development of art and
          signify major events or matters representing the history of art.
        </div> */}
        <Description parentNode={ref.current?.parentNode}>
          An artistic movement refers to a specific artistic trend or flow that
          emerges during a certain period or in a particular place. Artistic
          movements play a significant role in the development of art and
          signify major events or matters representing the history of art.
        </Description>
      </div>
      <div className={styles.container2}>
        <div className={styles.title}>
          BLOCKCHAIN
          <br />
          CREATES
          <br />
          ART HISTORY
        </div>
        {/* <div className={styles.description}>
          Artistic movements are closely associated with the social, political,
          and economic context of a specific era. They exhibit differences in
          artistic expression. Artistic movements are formed primarily around
          the works of artists active during a specific period. They play an
          important role in influencing the cultural impact of the era in which
          they emerge. It is evident that blockchain is having a disruptive
          impact on the art world. <br />
          <br />
          Blockchain provides artists with new ways to monetize their digital
          artworks. For instance, NFTs offer a method to represent ownership and
          trade traditional artworks, thus transforming the concept of value and
          ownership in art. This encourgages artists to create more innovative
          and experimental works using digital tools, which can lead to
          completely new movements in art.
        </div> */}
        <Description parentNode={ref.current?.parentNode}>
          {`Artistic movements are closely associated with the social, political,
          and economic context of a specific era. They exhibit differences in
          artistic expression. Artistic movements are formed primarily around
          the works of artists active during a specific period. They play an
          important role in influencing the cultural impact of the era in which
          they emerge. It is evident that blockchain is having a disruptive
          impact on the art world. 
          Blockchain provides artists with new ways to monetize their digital
          artworks. For instance, NFTs offer a method to represent ownership and
          trade traditional artworks, thus transforming the concept of value and
          ownership in art. This encourgages artists to create more innovative
          and experimental works using digital tools, which can lead to
          completely new movements in art.`}
        </Description>
      </div>
      <div className={styles.container3}>
        <div className={styles.subtitle}>
          HARNESSING BLOCKCHAIN WILL LEADS TO
        </div>
        <div className={styles.table_description}>
          <div className={styles.item}>
            <div className={styles.item_title}>1</div>
            <div className={styles.item_description}>
              Artworks that transcend time and space
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>2</div>
            <div className={styles.item_description}>
              Self-executing artworks composed of smart contracts
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>3</div>
            <div className={styles.item_description}>
              Artworks reflecting narratives based on ownership
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.item_title}>4</div>
            <div className={styles.item_description}>
              Artworks blurring the boundaries between collectors and artists
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container4}>
        <div className={styles.title_box_top}>
          <div className={styles.title_w}>WE ARE ENGINEERS WITH AN</div>
          <div className={styles.title_b}>ARTISTIC PERSPECTIVE.</div>
        </div>
        <div className={styles.title_box_bottom}>
          <div className={styles.title_w}>WE ARE CREATING</div>
          <div className={styles.title_b}>THE MOST INNOVATIVE ARTWORKS</div>
          <div className={styles.title_w}>IN CONTEMPORARY ART.</div>
        </div>
      </div>
      <div className={styles.container5}>
        WE ARE ESTABLISHING A NEW ARTISTIC MOVEMENT.
      </div>
    </div>
  );
};
