import styled from 'styled-components';
import PolarVideo from '../../../assets/polar.mp4';

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`;

export default function Contact() {
  const handleClick = (link: string) => {
    window.open(link);
  };
  return (
    <Wrapper>
      <VideoWrapper>
        <VideoContainer preload='auto' autoPlay muted loop playsInline>
          <source src={PolarVideo} type='video/mp4' />
        </VideoContainer>
        <ContactContainer>
          <PreText>Want to start a project with us?</PreText>
          <ContactText>LET'S TALK</ContactText>
          <ContactButton
            onClick={() => {
              window.location.href = 'mailto:support@nfthigher.io';
            }}
          >
            Partner with HYER
          </ContactButton>
        </ContactContainer>
        <Footer>
          <FooterTextWrapper>
            <FooterLink
              onClick={() => {
                handleClick('https://www.instagram.com/polar.art_official/');
              }}
            >
              Instagram
            </FooterLink>{' '}
            |{' '}
            <FooterLink
              onClick={() => {
                handleClick('https://club021.com');
              }}
            >
              CLUB021
            </FooterLink>
          </FooterTextWrapper>
          <FooterTextWrapper>
            2022 Higher Corp. All Rights Reserved.
          </FooterTextWrapper>
        </Footer>
      </VideoWrapper>
    </Wrapper>
  );
}

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-bottom: 60px;
  }

  font-family: Fira Mono;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  padding: 60px;
`;

const FooterTextWrapper = styled.div``;

const FooterLink = styled.span`
  cursor: pointer;
`;

const VideoWrapper = styled.div`
  /* position: absolute;
  top: 0; */
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  z-index: 5;
  justify-content: center;
  &:before {
    content: ''; // ::before and ::after both require content
    position: absolute;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;

    ${(props) =>
      props.theme.appearance.mode === 'light'
        ? `background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(255, 255, 255, 1) 100%
    )`
        : `background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    )`}
  }
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

const ContactContainer = styled.div`
  width: 100%;
  top: 50%;
  left: 50%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

const ContactButton = styled.button`
  background-color: ${(props) => props.theme.appearance.textColor};
  color: ${(props) => props.theme.appearance.bgColor};
  width: 178px;
  height: 51px;
  border-radius: 25px;
  font-family: paralucent;
  font-size: 1rem;
  font-weight: 300;
  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.appearance.textColor};
    border-color: ${(props) => props.theme.appearance.textColor};
    border: solid;
  }
`;

const PreText = styled.p`
  color: ${(props) => props.theme.appearance.textColor};
  font-size: 24px;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 16px;
  }
  font-weight: 500;
  font-family: paralucent;
  position: absolute;
  top: -140px;
  /* margin-bottom: 200px; */
`;
const ContactText = styled.p`
  color: ${(props) => props.theme.appearance.textColor};
  font-size: 100px;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 60px;
  }
  font-weight: 900;
  font-family: paralucent;
  margin-bottom: 20px;
`;

const VideoContainer = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.theme.appearance.mode === 'light' ? 'filter: invert(1)' : ''}
`;
