import { useTheme } from '../../../contexts/ThemeContext';
import styles from './styles.module.scss';

export const Team = () => {
  const { themeMode } = useTheme();

  return (
    <div
      className={`${styles.team} ${
        themeMode === 'light' ? styles.light : styles.dark
      }`}
    >
      <div className={styles.header}>TEAM</div>
      <div className={styles.title}>WHO WE ARE</div>
      <div className={styles.table}>
        <div className={styles.card}>
          <div className={styles.name}>BERRY</div>
          <div className={styles.position}>Representative</div>
          <div className={styles.school}>KAIST</div>
        </div>
        <div className={styles.card}>
          <div className={styles.name}>CHERRY</div>
          <div className={styles.position}>Designer</div>
          <div className={styles.school}>Hongik Univ</div>
        </div>
        <div className={styles.card}>
          <div className={styles.name}>DURIAN</div>
          <div className={styles.position}>Developer</div>
          <div className={styles.school}>KAIST</div>
        </div>
        <div className={styles.card}>
          <div className={styles.name}>PIZZA</div>
          <div className={styles.position}>Tech PM & Developer</div>
          <div className={styles.school}>Yonsei Univ</div>
        </div>
        <div className={styles.card}>
          <div className={styles.name}>CULT</div>
          <div className={styles.position}>Developer</div>
          <div className={styles.school}>KAIST</div>
        </div>
        <div className={styles.card}>
          <div className={styles.name}>COW</div>
          <div className={styles.position}>Developer</div>
          <div className={styles.school}>KAIST</div>
        </div>
      </div>
    </div>
  );
};
