import './App.css';
import { PageProvider } from './contexts/PageContext';
import styled from 'styled-components';
import Logo from './components/Logo';
import Menu from './components/Menu';
import Main from './components/pages/Main';
import GlobalStyle from './GlobalStyle';
import { ThemeProvider } from './contexts/ThemeContext';

const AppWrapper = styled.div`
  height: calc(var(--vh, 1vh) * 100);
`;

function App() {
  return (
    <ThemeProvider>
      <PageProvider>
        <AppWrapper className='App'>
          <GlobalStyle />
          <Logo />
          <Menu />
          <Main />
        </AppWrapper>
      </PageProvider>
    </ThemeProvider>
  );
}
export default App;
