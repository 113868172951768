import styled from 'styled-components';
import { useEffect } from 'react';
import init from './three';
import { useTheme } from '../../../contexts/ThemeContext';

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  align-items: center;
`;

const Canvas = styled.canvas`
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: ${(props) => (props.color === 'light' ? '#ffffff' : '#000000')};
`;

const LandingText = styled.div`
  z-index: 1;
  mix-blend-mode: difference;
  color: #fff;
  font-family: 'Paralucent';
  font-size: 1rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0;
  animation: reveal 1s 2s ease-in-out forwards;

  @keyframes reveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default function Landing() {
  const { themeMode } = useTheme();

  useEffect(() => {
    init();
  }, []);

  return (
    <Wrapper>
      <Canvas className='polar-cover-canvas' color={themeMode} />
      <LandingText>A NEW ARTISTIC MOVEMENT</LandingText>
    </Wrapper>
  );
}
