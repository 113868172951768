import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const USE_HELPER = false;
const USE_CONTROL = false;

let polar;

const clock = new THREE.Clock();

const loadPolar = (scene) => {
  const gltfLoader = new GLTFLoader();

  gltfLoader.load(
    `/1_Black.glb`,
    (gltf) => {
      polar = gltf.scene.children[0];
      polar.position.set(0, 0, 200);
      polar.scale.set(1, 1, 1);
      polar.rotation.set(
        -Math.PI / 2,
        (Math.PI * 3) / 4 + Math.PI / 4,
        Math.PI / 2
      );
      polar.castShadow = true;
      scene.add(polar);
    },
    (progress) => {},
    (error) => {
      console.log(error);
    }
  );
};

const createLights = (scene) => {
  const DISTANCE = 200;
  const INTENSITY = 0.3;
  [...Array(10)].forEach((e, i) => {
    const light = new THREE.DirectionalLight(0xffffff, INTENSITY);

    light.position.set(
      DISTANCE * Math.sin(((i + 1) * Math.PI) / 5),
      DISTANCE,
      DISTANCE * Math.cos(((i + 1) * Math.PI) / 5)
    );
    light.castShadow = true; // default false
    //Set up shadow properties for the light
    light.shadow.mapSize.width = 512; // default
    light.shadow.mapSize.height = 512; // default
    light.shadow.camera.near = 0.5; // default
    light.shadow.camera.far = 500; // default

    scene.add(light);

    if (USE_HELPER) {
      const helper = new THREE.DirectionalLightHelper(light, 50);
      scene.add(helper);
    }
  });

  [...Array(10)].forEach((e, i) => {
    const light = new THREE.DirectionalLight(0xffffff, INTENSITY);

    light.position.set(
      DISTANCE * Math.sin(((i + 1) * Math.PI) / 5),
      -DISTANCE,
      DISTANCE * Math.cos(((i + 1) * Math.PI) / 5)
    );
    scene.add(light);

    if (USE_HELPER) {
      const helper = new THREE.DirectionalLightHelper(light, 50);
      scene.add(helper);
    }
  });
};

const createCamera = () => {
  const camera = new THREE.PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    0.1,
    10000
  );

  camera.position.set(0, 0, 400);
  camera.lookAt(0, 0, 500);

  return camera;
};

const init = () => {
  if (window.appLoaded) {
    return false;
  }
  window.appLoaded = true;

  const canvas = document.querySelector('canvas.polar-cover-canvas');
  const renderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    alpha: true,
  });
  renderer.setClearColor(0xeeeeee, 0);
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.physicallyCorrectLights = true;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;

  const camera = createCamera();
  if (USE_CONTROL) {
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.update();
  }

  const scene = new THREE.Scene();

  if (USE_HELPER) {
    const axesHelper = new THREE.AxesHelper(500);
    scene.add(axesHelper);
  }

  loadPolar(scene);
  createLights(scene);

  renderer.render(scene, camera);

  function resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  function render() {
    const currentTime = clock.getElapsedTime();
    const angVel = { x: 0.4, y: 0.2, z: 0.6 };
    const delay = 0;
    if (currentTime < delay) {
    }
    if (currentTime < 2 + delay && currentTime > delay) {
      camera.lookAt(0, 0, 0);
      camera.position.set(0, 0, (1200 * (currentTime - delay)) / 2);
    }
    polar &&
      polar.rotation.set(
        angVel.x * currentTime,
        Math.PI + angVel.y * currentTime,
        Math.PI / 2 + angVel.z * currentTime
      );

    renderer.render(scene, camera);
    requestAnimationFrame(render);

    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.left = canvas.clientWidth / -2;
      camera.right = canvas.clientWidth / 2;
      camera.top = canvas.clientHeight / 2;
      camera.bottom = canvas.clientHeight / -2;
      camera.updateProjectionMatrix();
    }
  }

  // set event handlers
  const maxPos = 50;
  const onMouseMove = (event) => {
    const pointer = new THREE.Vector2();
    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;

    polar && polar.position.set(pointer.x * maxPos, pointer.y * maxPos, 0);
  };

  const onTouchMove = (event) => {
    const pointer = new THREE.Vector2();
    pointer.x = (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
    pointer.y = -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;

    polar && polar.position.set(pointer.x * maxPos, pointer.y * maxPos, 0);
  };

  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('touchmove', onTouchMove);

  requestAnimationFrame(render);
};

export default init;
