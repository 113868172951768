import { useCallback, useEffect, useRef, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import styles from './styles.module.scss';

interface ContentLineProps {
  parentNode: ParentNode | null | undefined;
  index: number;
  scrollRange: number;
  children: string;
}

interface DescriptionProps {
  parentNode: ParentNode | null | undefined;
  children: string;
}

const ContentLine = ({
  children,
  parentNode,
  index,
  scrollRange,
}: ContentLineProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    const handle = () => {
      if (contentRef.current) {
        const { top } = contentRef.current.getBoundingClientRect();
        const { innerHeight } = window;
        const scrollStart = innerHeight * (0.6 - scrollRange * index);
        const scrollValue = Math.max(
          0,
          Math.min(100, ((scrollStart - top) / scrollRange) * 100)
        );
        setScrollValue(scrollValue);
      }
    };

    parentNode?.addEventListener('scroll', handle);

    return () => {
      parentNode?.removeEventListener('scroll', handle);
    };
  }, [contentRef, parentNode, index]);

  return (
    <div className={styles.content_line} ref={contentRef}>
      <div className={styles.line}>{children}</div>
      <div
        className={styles.line_back}
        style={{
          width: `${scrollValue}%`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const Description = ({ children, parentNode }: DescriptionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [lines, setLines] = useState<string[]>([]);

  const separateDescription = useCallback(() => {
    if (ref.current) {
      const fontSize = window.innerWidth < 768 ? 13 : 18;
      const maxLength =
        ref.current.getBoundingClientRect().width / (fontSize / 2.21);
      const words = children.split(' ');
      console.log(ref.current.getBoundingClientRect().width);

      const tempLines = [];
      let tempLine = '';
      words.forEach((word) => {
        if (tempLine.length + word.length <= maxLength) {
          if (tempLine.length === 0) {
            tempLine = word;
          } else {
            tempLine = tempLine + ' ' + word;
          }
        } else {
          tempLines.push(tempLine);
          tempLine = word;
        }
      });
      if (tempLine.length > 0) {
        tempLines.push(tempLine);
      }

      setLines(tempLines);
    }
  }, [children, ref]);

  useEffect(() => {
    separateDescription();
    window.addEventListener('resize', separateDescription);
    return () => {
      window.removeEventListener('resize', separateDescription);
    };
  }, [separateDescription]);

  return (
    <div className={styles.description} ref={ref}>
      {lines.map((line, index) => (
        <ContentLine
          key={`line_${index}`}
          parentNode={parentNode}
          index={index}
          scrollRange={0.5 / lines.length}
        >
          {line}
        </ContentLine>
      ))}
    </div>
  );
};
