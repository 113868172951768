import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { MenuTitle } from '../../texts';
import { ProjectsListContainer } from './ProjectsListContainer';
import { ProjectContainer } from './ProjectContainer';

const Wrapper = styled.div`
  position: fixed;
  min-width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
`;

const MAX_POS_Y = 2000;
const MIN_POS_Y = 0;
const TOUCHMOVE = 'touchmove';
const TOUCHEND = 'touchend';

interface ComponentProps {
  isOnPage: boolean;
  blockScrollUp: Function;
  blockScrollDown: Function;
}

export default function Projects({
  isOnPage,
  blockScrollUp,
  blockScrollDown,
}: ComponentProps) {
  const [posY, setPosY] = useState(0);
  const pageContainer = useRef<HTMLDivElement>(null);

  const setNewPos = (deltaY: number) => {
    let newPos;
    if (posY + deltaY > MAX_POS_Y) {
      newPos = MAX_POS_Y;
    } else if (posY + deltaY < MIN_POS_Y) {
      newPos = MIN_POS_Y;
    } else {
      newPos = posY + deltaY;
    }
    setPosY(newPos);

    pageContainer.current!.style.transform = `translate3d(calc(${
      (100 * newPos) / MAX_POS_Y
    }* (1vw - 1%)), 0, 0)`;
  };

  const onScroll = (event: any) => {
    if (event.deltaY !== 0) {
      setNewPos(event.deltaY);
    }
  };

  useEffect(() => {
    let startTouchMove: number | null = null;
    let posYOnStart = 0;
    let newPos = 0;
    let deltaY;

    const touchMove = (event: any) => {
      if (startTouchMove !== null) {
        deltaY = (startTouchMove - event.touches[0].screenY) * 6;

        if (posYOnStart + deltaY > MAX_POS_Y) {
          newPos = MAX_POS_Y;
        } else if (posYOnStart + deltaY < MIN_POS_Y) {
          newPos = MIN_POS_Y;
        } else {
          newPos = posYOnStart + deltaY;
        }

        pageContainer.current!.style.transform = `translate3d(calc(${
          (100 * newPos) / MAX_POS_Y
        }* (1vw - 1%)), 0, 0)`;
        setPosY(newPos);
      } else {
        startTouchMove = event.touches[0].screenY;
        posYOnStart = posY;
      }
    };

    const touchEnd = (event: any) => {
      startTouchMove = null;
    };

    const instance = pageContainer.current;
    instance!.addEventListener(TOUCHMOVE, touchMove, false);
    instance!.addEventListener(TOUCHEND, touchEnd, false);

    return () => {
      instance!.removeEventListener(TOUCHMOVE, touchMove);
      instance!.removeEventListener(TOUCHEND, touchEnd);
    };
  }, [posY]);

  useEffect(() => {
    if (isOnPage === true) {
      blockScrollDown(true);
      blockScrollUp(true);
      if (posY === MAX_POS_Y) {
        blockScrollDown(false);
      }
      if (posY === MIN_POS_Y) {
        blockScrollUp(false);
      }
    } else {
      blockScrollUp(false);
      blockScrollDown(false);
    }
  }, [posY, isOnPage, blockScrollDown, blockScrollUp]);

  return (
    <Wrapper ref={pageContainer} onWheel={onScroll}>
      <MenuTitle>{'PROJECTS'}</MenuTitle>
      <ProjectsListContainer>
        <ProjectContainer />
      </ProjectsListContainer>
    </Wrapper>
  );
}
