import { useContext, useState } from 'react';
import { PageContext } from '../../../contexts/PageContext';

import { PageScroller } from '../../PageScroller';
import About from '../About';
import AboutWithVideos from '../About/AboutWithVideos';
import Video from '../About/Video';
import Contact from '../Contact';
import Landing from '../Landing';
import Projects from '../Projects';
import { Team } from '../Team';
import { Vision } from '../Vision';

export default function Main() {
  const { page, changePage } = useContext(PageContext);
  // const [page, setPage] = useState(0);

  const [blockScroll, setBlockScroll] = useState(false);
  const [blockScrollDown, setBlockScrollDown] = useState(false);
  const [blockScrollUp, setBlockScrollUp] = useState(false);

  const pageOnChange = (nextIndex: number) => {
    const timeout = setTimeout(() => {
      setBlockScroll(false);
      clearTimeout(timeout);
    }, 800);
  };
  // const onBeforePageScroll = (nextIndex: number) => {
  //   changePage(nextIndex);
  //   setBlockScroll(true);
  // };

  return (
    <>
      <Video display={page >= 2 && page <= 5} />
      <PageScroller
        animationDuration={1000}
        animationBuffer={500}
        page={page}
        setPage={changePage}
      >
        <Landing />
        <About />
        <AboutWithVideos
          numberTitle={1}
          description='Artworks that transcend time and space.'
        />
        <AboutWithVideos
          numberTitle={2}
          description='Self-executing artworks composed of smart contracts.'
        />
        <AboutWithVideos
          numberTitle={3}
          description='Artworks reflecting narratives based on ownership.'
        />
        <AboutWithVideos
          numberTitle={4}
          description='Artworks blurring the boundaries between collectors and artists'
        />
        <Vision />
        <Team />
        <Projects
          isOnPage={page === 7}
          blockScrollDown={setBlockScrollDown}
          blockScrollUp={setBlockScrollUp}
        />
        <Contact />
      </PageScroller>
      {/* <ReactPageScroller
        animationTimer={1000}
        animationTimerBuffer={200}
        pageOnChange={pageOnChange}
        customPageNumber={page}
        blockScrollDown={blockScrollDown || blockScroll}
        blockScrollUp={blockScrollUp || blockScroll}
        onBeforePageScroll={onBeforePageScroll}
        renderAllPagesOnFirstRender={true}
      >
        <Landing />
        <About />
        <AboutWithVideos
          numberTitle={1}
          description='HYER guides artists to web 3.0 space of infinite freedom.'
        />
        <AboutWithVideos
          numberTitle={2}
          description={`Artists can unfold artistic experiments in areas of NFT and
          blockchain.`}
        />
        <AboutWithVideos
          numberTitle={3}
          description={`Each artist leaves after building POLAR that resembles the artist
          itself.`}
        />
        <AboutWithVideos
          numberTitle={4}
          description={`Then POLAR is open for the next artists.`}
        />
        <Vision
          isOnPage={page === 6}
          blockScrollDown={setBlockScrollDown}
          blockScrollUp={setBlockScrollUp}
        />
        <Projects
          isOnPage={page === 7}
          blockScrollDown={setBlockScrollDown}
          blockScrollUp={setBlockScrollUp}
        />
        <Contact />
      </ReactPageScroller> */}
    </>
  );
}
