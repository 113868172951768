import styled from 'styled-components';
import SeonglibPolar from '../../../assets/seonglib_polar.png';

// const ProjectWrapper = styled.div`
//   width: 832px;
//   height: 762px;
//   border: 1px solid #000000;
// `;

const ProjectWrapper = styled.div`
  @media screen and (max-width: 768px) {
    width: 300px;
    height: 300px;
  }
  width: 600px;
  height: 600px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.appearance.textColor};
  background-image: url(${SeonglibPolar});
  background-size: cover;
  color: ${(props) => props.theme.appearance.bgColor};
  &:hover {
    cursor: pointer;
  }
`;

const ProjectTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px;
  /* padding-right: 30px; */
  & p {
    @media screen and (max-width: 768px) {
      font-size: 13px;
    }
    font-family: 'paralucent';
    font-size: 24px;
  }
  flex: 1;
`;

const ProjectTitleContainer = styled.div`
  display: flex;
`;

const ProjectArtistContainer = styled.div`
  display: flex;
`;

export const ProjectContainer = () => {
  return (
    <ProjectWrapper
      onClick={() => {
        window.open('https://projector.polar.art/');
      }}
    >
      <ProjectTextWrapper>
        <ProjectTitleContainer>
          <p>PROJECTOR</p>
        </ProjectTitleContainer>
        <ProjectArtistContainer>
          <p>Seonglib</p>
        </ProjectArtistContainer>
      </ProjectTextWrapper>
    </ProjectWrapper>
  );
};
