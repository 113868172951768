import styled from 'styled-components';
import { MenuTitle, HeadTitle } from '../../texts';
import { ReactComponent as HyerSymbol } from '../../../assets/hyer_symbol.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
`;

const SubTitleWithAnime = styled.p`
  display: block;
  font-family: paralucent;
  font-size: 48px;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 18px;
  }
  font-weight: 500;
  opacity: 0.2;
`;

export default function About() {
  return (
    <Wrapper>
      <MenuTitle>ABOUT</MenuTitle>
      <HeadTitle>
        <HyerSymbol />
      </HeadTitle>
      <SubTitleWithAnime>BLOCKCHAIN</SubTitleWithAnime>
      <SubTitleWithAnime>CREATES</SubTitleWithAnime>
      <SubTitleWithAnime>ART HISTORY</SubTitleWithAnime>
    </Wrapper>
  );
}
