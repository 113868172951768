import styled from 'styled-components';
import isMobile from '../../../utils/isMobile';

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100%;
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
`;

const Left = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${(props) => props.theme.appearance.textColor};
  opacity: 0;
  color: ${(props) => props.theme.appearance.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: paralucent;
  font-weight: 500;
`;

const Right = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 120px;
`;

const Top = styled.div`
  width: 100%;
  height: 50%;
  background-color: ${(props) => props.theme.appearance.textColor};
  opacity: 0;
  color: ${(props) => props.theme.appearance.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: paralucent;
  font-weight: 500;
`;

const Bottom = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 40px;
`;

const NumberTitle = styled.p`
  font-family: fira-mono;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.4;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-family: paralucent;
  font-size: 40px;
  line-height: 64px;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 20px;
    line-height: 32px;
  }
  font-weight: 300;
`;

interface AboutWithVideosProps {
  numberTitle: number;
  description: string;
}

export default function AboutWithVideos({
  numberTitle,
  description,
}: AboutWithVideosProps) {
  return isMobile() ? (
    <Wrapper>
      <Top />
      <Bottom>
        <NumberTitle>{numberTitle}</NumberTitle>
        <Description>{description}</Description>
      </Bottom>
    </Wrapper>
  ) : (
    <Wrapper>
      <Left />
      <Right>
        <NumberTitle>{numberTitle}</NumberTitle>
        <Description>{description}</Description>
      </Right>
    </Wrapper>
  );
}
