import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import useIsInViewport from '../../../hooks/useIsInViewport';
import LeftPolarVideoBlack from '../../../assets/fin_black.mp4';
import LeftPolarVideoWhite from '../../../assets/fin_white.mp4';
import gsap from 'gsap';
import { useTheme } from '../../../contexts/ThemeContext';
import isMobile from '../../../utils/isMobile';

const Wrapper = styled.div<{ display: boolean }>`
  display: ${(props) => (props.display ? 'flex' : 'none')};
  position: fixed;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
  }
  z-index: 6;
`;

const Left = styled.div`
  width: 50%;
  height: calc(var(--vh, 1vh) * 100);
  background-color: ${(props) => props.theme.appearance.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: paralucent;
  font-weight: 500;
  opacity: 0;
`;

const LeftVideo = styled.video`
  max-width: 100%;
  max-height: 100%;

  @media ${({ theme }) => theme.device.mobile} {
    max-height: none;
  }
`;

const Right = styled.div`
  width: 50%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 120px;
`;

const Top = styled.div`
  width: 100%;
  height: 50vh;
  background-color: ${(props) => props.theme.appearance.textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: paralucent;
  font-weight: 500;
  opacity: 0;
`;

const Bottom = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 120px;
`;

interface VideoProps {
  display: boolean;
}

export default function Video({ display }: VideoProps) {
  const Ref = useRef(null);
  const isInViewport = useIsInViewport(Ref);
  const { themeMode } = useTheme();

  useEffect(() => {
    gsap.fromTo(Ref.current, { opacity: 0 }, { opacity: 1, duration: 2 });
  }, [isInViewport]);

  return isMobile() ? (
    <Wrapper display={display}>
      <Top ref={Ref}>
        <LeftVideo
          preload='auto'
          autoPlay
          muted
          loop
          playsInline
          key={themeMode}
        >
          <source
            src={
              themeMode === 'light' ? LeftPolarVideoBlack : LeftPolarVideoWhite
            }
            type='video/mp4'
          />
        </LeftVideo>
      </Top>
      <Bottom />
    </Wrapper>
  ) : (
    <Wrapper display={display}>
      <Left ref={Ref}>
        <LeftVideo
          preload='auto'
          autoPlay
          muted
          loop
          playsInline
          key={themeMode}
        >
          <source
            src={
              themeMode === 'light' ? LeftPolarVideoBlack : LeftPolarVideoWhite
            }
            type='video/mp4'
          />
        </LeftVideo>
      </Left>
      <Right />
    </Wrapper>
  );
}
